import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as OrderPaymentMethods from '@shared/core/services/orderPaymentMethods';

import { Observable, of, forkJoin, from, throwError } from 'rxjs';
import { catchError, map, switchMap, take, filter, withLatestFrom, delay, mergeMap, auditTime } from 'rxjs/operators';

@Injectable()
export class PaymentsEffects {
    @Effect() public paymentInitWithPrevalidatedCard$: Observable<Action> = this._actions$.pipe(
        ofType(actions.PaymentInit, actions.PaymentInitWithPaymentMethod, actions.PaymentInitWithRedirect),
        withLatestFrom(
            this._store.pipe(select(selectors.getCardsState)),
            this._store.pipe(select(selectors.getMemberState)),
            this._store.pipe(select(selectors.paymentHasErrors)),
            this._store.pipe(select(selectors.getPaymentStepsStatus)),
            this._store.pipe(select(selectors.getPaymentState)),
        ),
        switchMap(([, , memberState, paymentHasErrors, step, paymentState]) => {
            if (paymentHasErrors) {
                if (paymentState.orderId && paymentState.data.TransactionId) {
                    return [actions.PaymentClearErrors(), actions.PaymentStepPaymentStatusCheck({ TransactionId: paymentState.data.TransactionId, OrderId: paymentState.orderId })];
                }

                return this._error('#33 Payment process has errors');
            }

            if (step !== 'init') {
                return this._error(`#37 Payment step error. Should be "init", is: ${step}`);
            }

            if (this._config.payments.baseProvider == null && this._config.payments.payInStore !== true) {
                return this._error('#41 Payment provider not defined. Either define it in your config file or set payInStore flag to true');
            }

            const isGuest: boolean = memberState.isGuestModeEnabled && memberState.guestData !== null;
            if (isGuest && this._config.demoMode !== true) {
                return [
                    actions.MemberValidateEmailDataRequest({ email: memberState.guestData.Email, memberPhoneIdPriority: true }),
                    actions.MemberValidatePhoneRequest({ phone: memberState.guestData.MobileNumber, memberPhoneIdPriority: true }),
                    actions.PaymentStepValidateGuestData(null),
                ];
            }

            return [actions.PaymentStepCreateOrder()];
        }),
    );

    @Effect() public stepValidateGuestData$: Observable<Action> = this._actions$.pipe(
        ofType(actions.PaymentStepValidateGuestData),
        switchMap((action) =>
            this._store.pipe(
                select(selectors.getMemberState),
                filter((member) => member.validateEmail.isValidating !== true && member.validatePhone.isValidating !== true),
                take(1),
                switchMap((state) => {
                    if (state.validateEmail.hasFailed || state.validatePhone.hasFailed) {
                        return this._error('#120 Invalid guest\'s email address or phone number');
                    }

                    return forkJoin(
                        this._membersService.validateMemberByProperty(
                            state.guestData.MobileNumber,
                            state.guestData.MobilePhoneCountryId,
                            OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN,
                        ),
                        this._membersService.validateMemberByProperty(state.guestData.Email, state.guestData.MobilePhoneCountryId, OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN),
                    ).pipe(
                        withLatestFrom(this._store.pipe(select(selectors.paymentHasErrors)), this._store.pipe(select(selectors.getPaymentStepsStatus))),
                        switchMap(([, paymentHasErrors, step]) => {
                            if (paymentHasErrors) {
                                return this._error('#133 Payment process has errors');
                            }

                            if (step !== 'validate_guest') {
                                return this._error(`#137 Payment step error. Should be "validate_guest", is: ${step}`);
                            }

                            const nextStep = () => {
                                /* If card is provided but not saved, run these steps */
                                if (action.creditCard) {
                                    switch (this._config.payments.baseProvider) {
                                        case OLO.Enums.PAYMENT_PROVIDER.CONVERGE:
                                            return [
                                                actions.GetCreditCardToken({
                                                    cardNumber: action.creditCard.cardNo,
                                                    expiryDate: action.creditCard.expDate,
                                                    saveCard: action.creditCard.save,
                                                }),
                                                actions.PaymentStepValidateGuestCardToken(),
                                            ];

                                        case OLO.Enums.PAYMENT_PROVIDER.PAYMENT_EXPRESS:
                                        case OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA:
                                        case OLO.Enums.PAYMENT_PROVIDER.ADYEN:
                                            return of(actions.PaymentStepCreateOrder());

                                        case OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT:
                                            console.warn('TODO - HANDLE CARD CONNECT');

                                            return [];

                                        default:
                                            console.error('Payment provider not provided in config.js');

                                            return [];
                                    }
                                }

                                return of(actions.PaymentStepCreateOrder());
                            };

                            return nextStep();
                        }),
                    );
                }),
            ),
        ),
        catchError((ex) => this._error('#212 Payment failed', ex)),
    );

    @Effect() public stepValidateGuestCardTokens$: Observable<Action> = this._actions$.pipe(
        ofType(actions.PaymentStepValidateGuestCardToken),
        switchMap(() =>
            this._store.pipe(
                select(selectors.getCardsState),
                filter(
                    (cardsState) =>
                        cardsState.token.isGettingToken === false &&
                        ((cardsState.token.hasSucceeded === true && cardsState.activeCardToken !== null) || cardsState.token.hasFailed === true),
                ),
                take(1),
                withLatestFrom(this._store.pipe(select(selectors.paymentHasErrors)), this._store.pipe(select(selectors.getPaymentStepsStatus))),
                switchMap(([cardsState, paymentHasErrors, step]) => {
                    if (paymentHasErrors) {
                        return this._error('#234 Payment process has errors');
                    }

                    if (step !== 'validate_card_token') {
                        return this._error(`#238 Payment step error. Should be "validate_card_token", is: ${step}`);
                    }

                    if (cardsState.token.hasFailed || cardsState.activeCardToken === null) {
                        return this._error('#242 Unable to get card token');
                    }

                    return of(actions.PaymentStepCreateOrder());
                }),
            ),
        ),
    );

    @Effect() public stepCreateOrder$: Observable<Action> = this._actions$.pipe(
        ofType(actions.PaymentStepCreateOrder),
        withLatestFrom(
            this._store.pipe(select(selectors.canPostOnlineOrder)),
            this._store.pipe(select(selectors.canPayForOnlineOrder)),
            this._store.pipe(select(selectors.isPaymentProcessValid)),
            this._store.pipe(select(selectors.paymentHasErrors)),
            this._store.pipe(select(selectors.getPaymentStepsStatus)),
            this._store.pipe(select(selectors.isCartZeroPrice)),
        ),
        switchMap(([_, canPostOnlineOrder, canPayForOnlineOrder, isPaymentProcessValid, paymentHasErrors, step, isZeroPricedOrder]) => {
            if (!canPostOnlineOrder) {
                return this._error('#264a Unable to create order - insufficient data or data corrupted');
            }

            if (step !== 'create_order') {
                return this._error(`#268 Payment step error. Should be "create_order", is: ${step}`);
            }

            if (isZeroPricedOrder) {
                return [actions.OnlineOrderClearPostOrderRequestFlags(), actions.OnlineOrderCreateRequest(), actions.PaymentStepSkipForZeroPricedOrder()];
            }

            if (!canPayForOnlineOrder || !isPaymentProcessValid || paymentHasErrors) {
                console.warn('canPayForOnlineOrder', canPayForOnlineOrder);
                console.warn('isPaymentProcessValid', isPaymentProcessValid);
                console.warn('paymentHasErrors', paymentHasErrors);

                return this._error('#264b Unable to create order - payment validation or corrupted data');
            }

            this._store.dispatch(actions.OnlineOrderClearPostOrderRequestFlags());
            this._store.dispatch(actions.OnlineOrderCreateRequest());

            return this._store.pipe(
                select(selectors.getOnlineOrderState),
                auditTime(100),
                filter((orderState) => orderState.createRequest.isCreating === false),
                take(1),
                withLatestFrom(this._store.pipe(select(selectors.getPaymentState))),
                switchMap(([orderState, paymentState]) => {
                    if (orderState.createRequest.hasFailed) {
                        return this._error('#254 Unable to create order');
                    }

                    if (paymentState.redirect.vendorPayment === true) {
                        return [actions.PaymentStepRedirect()];
                    }

                    return [actions.PaymentStepPay()];
                }),
            );
        }),
    );

    @Effect() public stepRedirect$: Observable<Action> = this._actions$.pipe(
        ofType(actions.PaymentStepRedirect),
        switchMap(() => {
            const redirectProvider = () => this._paymentsService.windcavePaymentProviderService.requestRedirectUrl({});

            return redirectProvider().pipe(
                switchMap(({ redirectUrl, sessionToken }) =>
                    from(Utils.Redirect.setRedirectAsync()).pipe(
                        take(1),
                        switchMap(() => {
                            setTimeout(() => {
                                this._store.dispatch(actions.StateSave());
                            }, 0);

                            return this._actions$.pipe(
                                ofType(actions.StateSaveSuccess, actions.StateSaveError),
                                take(1),
                                switchMap((saveAction) => {
                                    if (saveAction.type === actions.StateSaveSuccess.type) {
                                        Utils.Storage.set(OLO.Enums.SESSION_STORAGE.VENDOR_PAYMENT_DETAILS_COLLECT, '1', 'sessionStorage');
                                        window.location.href = `${redirectUrl}?token=${sessionToken}`;

                                        return [];
                                    }

                                    return this._error('#298 Unable to save state');
                                }),
                            );
                        }),
                    ),
                ),
            );
        }),
        catchError((ex) => this._error('#318 Redirect url request error', ex)),
    );

    public stepPay$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.PaymentStepPay),
            switchMap(() =>
                this._store.pipe(
                    select(selectors.getOnlineOrderState),
                    filter((orderState) => orderState.createRequest.isCreating === false),
                    take(1),
                    withLatestFrom(
                        this._store.pipe(select(selectors.getPaymentState)),
                        this._store.pipe(select(selectors.getCardsState)),
                        this._store.pipe(select(selectors.isAccountChargeSelected)),
                    ),
                    switchMap(([orderState, paymentState, cardState, isAccountChargeSelected]) => {
                        if (orderState.createRequest.hasFailed) {
                            return this._error('#299 Unable to create order');
                        }

                        const onlineOrder = orderState.data;

                        if (onlineOrder.Status === OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED) {
                            return [actions.PaymentStepComplete({ OrderId: onlineOrder.Id, payload: {} })];
                        }

                        return this._store.pipe(
                            select(selectors.isCartLocationsPickupsCalculating),
                            filter((isCalculating) => isCalculating === false),
                            take(1),
                            withLatestFrom(
                                this._store.pipe(select(selectors.paymentHasErrors)),
                                this._store.pipe(select(selectors.getPaymentStepsStatus)),
                                this._store.pipe(select(selectors.isGuestMember)),
                                this._store.pipe(select(selectors.getCurrentMember)),
                                this._store.pipe(select(selectors.getGuestData)),
                                this._store.pipe(select(selectors.getCartLocationNo)),
                            ),
                            switchMap(([_, paymentHasErrors, step, isGuest, memberData, guestData, locationNo]) => {
                                if (paymentHasErrors) {
                                    return this._error('#312 Unable to create order due to payment process errors');
                                }

                                if (step !== 'paying') {
                                    return this._error(`#317 Payment step error. Should be "paying", is: ${step}`);
                                }

                                const orderPaymentWithAccountCharge = new OrderPaymentMethods.OrderPaymentWithAccountCharge();
                                const orderPaymentWithGooglePay = new OrderPaymentMethods.OrderPaymentWithGooglePay();
                                const orderPaymentWithApplePay = new OrderPaymentMethods.OrderPaymentWithApplePay();
                                const orderPaymentWithConverge = new OrderPaymentMethods.OrderPaymentWithConverge();
                                const orderPaymentWithPaymentExpress = new OrderPaymentMethods.OrderPaymentWithPaymentExpress();
                                const orderPaymentWithCardConnect = new OrderPaymentMethods.OrderPaymentWithCardConnect();
                                const orderPaymentWithFatZebra = new OrderPaymentMethods.OrderPaymentWithFatZebra(this._fatZebra3DSPaymentProviderService);
                                const orderPaymentWithAdyen = new OrderPaymentMethods.OrderPaymentWithAdyen();
                                const orderPaymentWithStripe = new OrderPaymentMethods.OrderPaymentWithStripe();

                                orderPaymentWithAccountCharge.setNext(orderPaymentWithGooglePay);
                                orderPaymentWithGooglePay.setNext(orderPaymentWithApplePay);
                                orderPaymentWithApplePay.setNext(orderPaymentWithConverge);
                                orderPaymentWithConverge.setNext(orderPaymentWithPaymentExpress);
                                orderPaymentWithPaymentExpress.setNext(orderPaymentWithCardConnect);
                                orderPaymentWithCardConnect.setNext(orderPaymentWithFatZebra);
                                orderPaymentWithFatZebra.setNext(orderPaymentWithAdyen);
                                orderPaymentWithAdyen.setNext(orderPaymentWithStripe);

                                return from(
                                    orderPaymentWithAccountCharge.generatePaymentDetails({
                                        paymentProvider: this._config.payments.baseProvider,
                                        paymentMethod: paymentState.paymentMethod,
                                        onlineOrder,
                                        cardState,
                                        currency: this._config.localization.currency,
                                        member: isGuest ? guestData : memberData,
                                        locationNo,
                                    }),
                                ).pipe(
                                    switchMap((paymentDetails) => {
                                        const orderId = onlineOrder.Id;
                                        if (isAccountChargeSelected) {
                                            return this._paymentsService.payWithAccountCharge(orderId, paymentDetails).pipe(switchMap(this._statusCheckHandler(orderId)));
                                        }

                                        return this._paymentsService.pay(orderId, paymentDetails as OLO.DTO.ExecutePaymentModel).pipe(
                                            map(({ TransactionId }) => actions.PaymentStepPaymentStatusCheck({ TransactionId, OrderId: orderId })),
                                            catchError((ex) => this._error('#350 Payment failed', ex)),
                                        );
                                    })
                                );
                            }),
                        );
                    }),
                ),
            ),
            catchError((ex) => this._error('#405 Payment failed', ex))
        ),
    );

    @Effect() public stepPaymentStatusCheck$: Observable<Action> = this._actions$.pipe(
        ofType(actions.PaymentStepPaymentStatusCheck),
        withLatestFrom(this._store.pipe(select(selectors.getPaymentStepsStatus)), this._store.pipe(select(selectors.isPayingWithApplePay))),
        mergeMap(([action, step, isPayingWithApplePay]) => {
            if (this._config.demoMode) {
                return [actions.PaymentStepComplete({ OrderId: action.OrderId, payload: {} })];
            }

            if (step !== 'payment_status_check') {
                return this._error(`#361 Payment step error. Should be "payment_status_check", is: ${step}`);
            }

            return this._paymentsService.getPaymentStatus(action.TransactionId).pipe(
                delay(1000),
                switchMap((payload) => {
                    if (isPayingWithApplePay && payload.Status === OLO.Enums.PAYMENT_STATUS.SUCCESS) {
                        this._paymentsService.applePayPaymentProviderService.completeSuccessApplePay();
                    }

                    if (isPayingWithApplePay && payload.Status === OLO.Enums.PAYMENT_STATUS.FAILED) {
                        this._paymentsService.applePayPaymentProviderService.completeErrorApplePay();
                    }

                    return this._statusCheckHandler(action.OrderId, action.TransactionId)(payload);
                })
            );
        }),
        catchError((ex) => this._error('#382 Payment status check failed', ex)),
    );

    @Effect() public stepSkipPay$: Observable<Action> = this._actions$.pipe(
        ofType(actions.PaymentStepSkipForZeroPricedOrder),
        switchMap(() =>
            this._store.pipe(
                select(selectors.getOnlineOrderState),
                filter((orderState) => orderState.createRequest.isCreating === false),
                take(1),
                withLatestFrom(this._store.pipe(select(selectors.getOnlineOrder))),
                switchMap(([orderState, onlineOrder]) => {
                    if (orderState.createRequest.hasFailed) {
                        return this._error('#299a Unable to create order');
                    }

                    return of(actions.PaymentStepComplete({ OrderId: onlineOrder.Id, payload: {} }));
                }),
            ),
        ),
        catchError((ex) => this._error('#350a Skip Payment failed', ex)),
    );

    @Effect() public cleanUpOnSuccessfulPayment$ = this._actions$.pipe(
        ofType(actions.PaymentStepComplete),
        switchMap((action) => {
            const bundleActions: Action[] = [
                actions.CreditCardTokenDataReset(),
                actions.OnlineOrderStateReset(),
                actions.CartReset(),
                actions.LocationsFiltersReset(),
                actions.CurrentLocationReset(),
                actions.CreditCardsStateReset(),
                actions.CartRemoveActivatedVoucher(),
            ];

            if (this._config.onlineOrders?.sendAutoConfirmationEmail === true) {
                bundleActions.push(actions.OnlineOrderSendConfrimationEmailRequest({ orderId: action.OrderId }));
            }

            return bundleActions;
        }),
    );

    @Effect() public resetAdyenCVVOnCreditCardError$: Observable<Action> = this._actions$.pipe(
        ofType(actions.PaymentStepFailed),
        switchMap(() => [actions.CreditCardsAdyenRemoveCVVNumber()])
    );

    @Effect() public cancelOrderWhenPaymentFailed$ = this._actions$.pipe(
        ofType(actions.PaymentStepFailed),
        switchMap(() => this._store.pipe(
            select(selectors.getOnlineOrderState),
            take(1),
            switchMap(order => {
                const orderId = order.data.Id;
                if (orderId) {
                    return [actions.OnlineOrderCancelRequest({ orderId, updateHistoryOrder: false })];
                }

                return [];
            })
        ))
    );

    private _statusCheckHandler(
        OrderId: number,
        TransactionId?: string,
    ): (payload: OLO.DTO.GetTransactionResponse | OLO.DTO.ExecuteAccountChargeResponse) => Observable<Action> | Array<Action> {
        return (payload) => {
            switch (payload.Status) {
                case OLO.Enums.PAYMENT_STATUS.SUCCESS:
                    return [actions.PaymentStepComplete({ OrderId, payload })];

                case OLO.Enums.PAYMENT_STATUS.FAILED:
                    this._store.dispatch(actions.PaymentReset());

                    return this._error('#373 Payment declined by Payment Provider. Status: ' + payload.Status, payload.Status);

                default:
                    return TransactionId ? [actions.PaymentStepPaymentStatusCheck({ TransactionId, OrderId })] : [];
            }
        };
    }

    private _error(error: string = '', ex: any = null): Observable<Action> {
        console.error('Payment error:', error, ex);

        this._store.dispatch(actions.OnlineOrderClearPostOrderRequestFlags());
        this._store.dispatch(actions.PaymentStepFailed(error));

        // Needs to explicitly throw an error - otherwise stream will break and we will no longer have
        // proper connection with payment effects.
        // TODO: check why and where it breaks. I assume that it could be caused in mergeMap or by the different return types in operators - like map vs switchMap.
        return throwError(() => new Error(ex));
        // return throwError(ex);
    }

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _paymentsService: Services.PaymentsService,
        private _membersService: Services.MembersService,
        private _store: Store<OLO.State>,
        private _fatZebra3DSPaymentProviderService: Services.FatZebra3DSPaymentProviderService,
    ) {}
}
