import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { PickupsService } from './pickups.shared.service';

import { Observable, of, throwError } from 'rxjs';
import { map, take, switchMap, filter, withLatestFrom, catchError, auditTime, combineLatest } from 'rxjs/operators';
import { OnlineOrdersMapper } from '@shared/core/mappers/online-orders.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class OnlineOrdersService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public httpClient: HttpClient,
        public store: Store<OLO.State>,
        @Optional() public pickupsService: PickupsService
    ) {}

    public createNewOnlineOrder(model: OLO.DTO.OnlineOrderDetailedBusinessModel): Observable<OLO.DTO.OnlineOrderDetailedBusinessModel> {
        const { discounts, sendAutoReceiptEmail } = this.config.onlineOrders;

        if (discounts) {
            model = {
                ...model,
                OnlineDiscounts: [{
                    Id: null,
                    OnlineOrderId: model.Id || null,
                    Value: discounts,
                }]
            };
        }

        if (!sendAutoReceiptEmail) {
            model = {
                ...model,
                ReceiptNotificationEmailAdresses: null
            };
        }

        const mapedPostmodel: APIv3.OnlineOrderDetailedBusinessModel = OnlineOrdersMapper.mapCreateNewOnlineOrderPOSTRequest(model);

        return this.httpClient
            .post<APIv3.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders`, mapedPostmodel)
            .pipe(
                map((response: APIv3.OnlineOrderDetailedBusinessModel) => OnlineOrdersMapper.mapCreateNewOnlineOrderPOSTResponse(response))
            );
    }

    public updateOnlineOrder(model: OLO.DTO.OnlineOrderDetailedBusinessModel): Observable<OLO.DTO.OnlineOrderDetailedBusinessModel> {
        const mappedPutmodel: APIv3.OnlineOrderDetailedBusinessModel = OnlineOrdersMapper.mapCreateNewOnlineOrderPOSTRequest(model);

        return this.httpClient
            .put<APIv3.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders`, mappedPutmodel)
            .pipe(
                map((response: APIv3.OnlineOrderDetailedBusinessModel) => OnlineOrdersMapper.mapCreateNewOnlineOrderPOSTResponse(response))
            );
    }

    public cancelOnlineOrder(orderId: number): Observable<boolean> {
        return this.httpClient
            .put<boolean>(`${this.config.api.base}/OnlineOrders/${orderId}/Cancel`, null);
    }

    public sendEmailWithOrderConfirmation(orderId: number): Observable<boolean> {
        const mapedPostmodel: APIv3.OnlineOrderEmailConfirmationRequestModel = OnlineOrdersMapper.mapSendEmailWithOrderConfirmationPOSTRequest({
            OnlineOrderId: orderId,
            LoyaltyMobileAppId: null
        });

        return this.httpClient
            .post<APIv3.OnlineOrdersSendOnlineOrderConfirmationEmail.Responses.$200>(`${this.config.api.base}/OnlineOrders/sendOrderConfirmationEmail`, mapedPostmodel)
            .pipe(
                map((response: APIv3.OnlineOrdersSendOnlineOrderConfirmationEmail.Responses.$200) => OnlineOrdersMapper.mapSendEmailWithOrderConfirmationPOSTResponse(response))
            );
    }


    public recalculateOnlineOrder(model: OLO.DTO.OnlineOrderDetailedBusinessModel): Observable<OLO.DTO.OnlineOrderDetailedBusinessModel> {
        const { discounts } = this.config.onlineOrders;
        if (discounts) {
            model = {
                ...model,
                OnlineDiscounts: [{
                    Id: null,
                    OnlineOrderId: model.Id || null,
                    Value: discounts,
                }]
            };
        }

        const mapedPostmodel: APIv3.OnlineOrderDetailedBusinessModel = OnlineOrdersMapper.mapRecalculateOnlineOrderPOSTRequest(model);

        return this.httpClient
            .post<APIv3.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders/Recalculate`, mapedPostmodel)
            .pipe(
                map((response: APIv3.OnlineOrderDetailedBusinessModel) => OnlineOrdersMapper.mapRecalculateOnlineOrderPOSTResponse(response))
            );
    }

    public addVoucherOnlineOrder(model: OLO.DTO.ActivateVoucherRequest): Observable<OLO.DTO.OnlineOrderBusinessModel> {
        const { discounts } = this.config.onlineOrders;
        if (discounts) {
            model.Order = {
                ...model.Order,
                OnlineDiscounts: [{
                    Id: null,
                    OnlineOrderId: model.Order.Id || null,
                    Value: discounts,
                }]
            };
        }

        const mapedPostmodel: APIv3.ActivateVoucherRequest = OnlineOrdersMapper.mapAddVoucherOnlineOrderPOSTRequest(model);

        return this.httpClient
            .post<APIv3.OnlineOrderBusinessModel>(`${this.config.api.base}/OnlineOrders/activateVoucher`, mapedPostmodel)
            .pipe(
                map((response: APIv3.OnlineOrderBusinessModel) => OnlineOrdersMapper.mapAddVoucherOnlineOrderPOSTResponse(response))
            );
    }

    public removeVoucherOnlineOrder(model: OLO.DTO.DeactivateVoucherRequest): Observable<OLO.DTO.OnlineOrderBusinessModel> {
        const { discounts } = this.config.onlineOrders;
        if (discounts) {
            model.Order = {
                ...model.Order,
                OnlineDiscounts: [{
                    Id: null,
                    OnlineOrderId: model.Order.Id || null,
                    Value: discounts,
                }]
            };
        }

        const mapedPostmodel: APIv3.DeactivateVoucherRequest = OnlineOrdersMapper.mapRemoveVoucherOnlineOrderPOSTRequest(model);

        return this.httpClient
            .post<APIv3.OnlineOrderBusinessModel>(`${this.config.api.base}/OnlineOrders/deactivateVoucher`, mapedPostmodel)
            .pipe(
                map((response: APIv3.OnlineOrderBusinessModel) => OnlineOrdersMapper.mapRemoveVoucherOnlineOrderPOSTResponse(response))
            );
    }

    public getOnlineOrders(p: APICommon.OnlineOrdersGetOrdersParams = {}): Observable<OLO.DTO.PaginatedListOnlineOrderDetailedBusinessModel> {
        return this.httpClient
            .get<APIv3.PaginatedListOnlineOrderDetailedBusinessModel>(`${this.config.api.base}/members/my/onlineOrders${Utils.HTTP.object2string(p)}`)
            .pipe(
                map((response: APIv3.PaginatedListOnlineOrderDetailedBusinessModel) => OnlineOrdersMapper.mapOnlineOrdersGETResponse(response))
            );
    }

    public getOnlineOrder(orderId: number): Observable<OLO.DTO.OnlineOrderDetailedBusinessModel> {
        return this.httpClient
            .get<APIv3.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders/${orderId}`)
            .pipe(
                map((response: APIv3.OnlineOrderDetailedBusinessModel) => OnlineOrdersMapper.mapOnlineOrderGETResponse(response))
            );
    }

    public requestActiveOrders(params: APICommon.OnlineOrdersGetOrdersParams = {}): void {
        this.store.dispatch(actions.HistoryOrdersRequest({
            'pagingArgs.pageNo': 1,
            'statuses': [
                OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED,
                OLO.Enums.ONLINE_ORDER_STATUS.RECIVED_AT_SITE,
                OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_KITCHEN,
                OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_SITE
            ],
            ...params
        }));
    }

    public requestOrders(params: APICommon.OnlineOrdersGetOrdersParams = {}, pageNo: number): void {
        if (pageNo > 1) {
            this.store.dispatch(actions.HistoryOrdersLoadMoreRequest({
                'pagingArgs.pageNo': pageNo,
                'pagingArgs.pageSize': this.config.historyOrdersPage.ordersLimit,
                'statuses': [
                    OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED,
                ],
                ...params
            }));
        } else {
            this.store.dispatch(actions.HistoryOrdersRequest({
                'pagingArgs.pageNo': pageNo,
                'pagingArgs.pageSize': this.config.historyOrdersPage.ordersLimit,
                'statuses': [
                    OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED,
                    OLO.Enums.ONLINE_ORDER_STATUS.RECIVED_AT_SITE,
                    OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_KITCHEN,
                    OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_SITE,
                    OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED,
                ],
                ...params
            }));
        }
    }

    public requestPreviousOrdersForReorder(params: APICommon.OnlineOrdersGetOrdersParams = {}, _pageSize: number = 4): void {

        this.store.pipe(
            select(selectors.getCurrentLocationNo),
            take(1)
        ).subscribe(locNo => {
            let locationNo: number = locNo;

            this.store.dispatch(actions.HistoryOrdersRequest({
                'pagingArgs.pageNo': 1,
                'pagingArgs.pageSize': this.config.historyOrdersPage.ordersLimit,
                'includeFinalized': true,
                locationNo,
                'statuses': [
                    OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED,
                ],
                ...params
            }));
        });
    }

    public cleanHistoryOrderForGuest(): void {
        this.store
            .pipe(
                select(selectors.isGuestModeEnabled),
                take(1),
            ).subscribe(guestMode => {
                if (!guestMode) return;

                this.store.dispatch(actions.HistoryOrdersReset());
            });
    }

    public recalculateOrderAction(): void {

        this.store.dispatch(actions.OnlineOrderRecalculateRequest());
    }

    private _createSummaryPayment(recalcOrder: OLO.State.OnlineOrder['recalculateRequest']): Observable<OLO.Ordering.PaymentSummary> {
        return this.store
            .pipe(
                select(selectors.getPaymentStepsStatus),
                auditTime(200),
                filter(status => status === 'complete' || status === 'failed'),
                withLatestFrom(
                    this.store.pipe(select(selectors.getPaymentState))
                ),
                take(1),
                map(([status, paymentState]) =>
                    ({
                        status: status === 'failed' ? OLO.Enums.PAYMENT_STATUS.FAILED : OLO.Enums.PAYMENT_STATUS.SUCCESS,
                        orderId: paymentState.orderId,
                        orderTypeId: recalcOrder.data.OrderTypeId,
                        locationNo: recalcOrder.data.PickupLocation
                    })
                )
            );
    }

    public placeOrderWithRedirectPaymentProvider(): void {
        this.pickupsService.validateCartWithPopup()
            .pipe(
                take(1),
            )
            .subscribe(isCartValid => {
                if (isCartValid) {
                    this.store.dispatch(actions.PaymentInitWithRedirect());
                }
            });
    }
    // public async placeOrder(creditCard: State.IPaymentCreditCardData = null, paymentMethod: State.IPaymentMethod = null): Promise<OLO.Ordering.IPaymentSummary> {
    public async placeOrder(paymentConfig: OLO.Ordering.PaymentConfig = {}): Promise<OLO.Ordering.PaymentSummary> {

        return new Promise((resolve, reject) => {
            if (!this.pickupsService) {
                return reject('pickupsService not provided');
            };
            this.pickupsService.validateCartWithPopup()
                .pipe(
                    take(1),
                    withLatestFrom(
                        this.store.pipe(select(selectors.getOnlineOrderRecalcData))
                    ),
                    switchMap(([isCartValid, recalcOrder]) => {
                        if (isCartValid) {
                            if (paymentConfig?.paymentMethod) {
                                this.store.dispatch(actions.PaymentInitWithPaymentMethod(paymentConfig.paymentMethod));
                            } else {
                                this.store.dispatch(actions.PaymentInit());
                            }
                        } else {
                            return of(null);
                        }

                        return this._createSummaryPayment(recalcOrder);
                    }),
                    catchError(ex => {
                        console.error('Unable to make payment', ex);

                        return throwError(ex);
                    })
                )
                .subscribe((summary: OLO.Ordering.PaymentSummary) => {

                    if (!summary || summary.status !== OLO.Enums.PAYMENT_STATUS.SUCCESS || !summary.locationNo || !summary.orderId) {
                        console.error('Invalid payment summary', summary);

                        return reject(summary);
                    }
                    resolve(summary);
                }, () => reject());
        });
    }

    public sendOnlineOrderReceipt(orderId: number): Observable<boolean> {

        const mapedPostmodel: APIv3.OnlineOrderEmailReceiptRequestModel = OnlineOrdersMapper.mapSendOnlineOrderReceiptPOSTRequest({
            LoyaltyMobileAppId: null,
            OnlineOrderId: orderId
        });

        return this.httpClient
            .post<APIv3.OnlineOrdersSendOnlineOrderReceiptEmail.Responses.$200>(`${this.config.api.base}/OnlineOrders/sendOnlineOrderReceiptEmail`, mapedPostmodel)
            .pipe(
                map((response: APIv3.OnlineOrdersSendOnlineOrderReceiptEmail.Responses.$200) => OnlineOrdersMapper.mapSendOnlineOrderReceiptPOSTResponse(response))
            );
    }

    public requestHistoryOrder(orderId: number): void {
        this.store
            .pipe(
                select(selectors.getHistoryOrderObjectByOrderId(orderId)),
                take(1),
                filter(obj => Boolean(obj?.isDownloading) === false && Boolean(obj?.data) === false),
            ).subscribe(() => this.store.dispatch(actions.HistoryOrderRequest({ orderId })));
    }

    public reorderSetup(orderId: number, locationNo: number, modalId: number = null): void {
        this.store.dispatch(actions.ReorderSetup({ orderId, locationNo, modalId }));
    }

    public reorderInitCalculations(orderId: number): void {
        this.store
            .pipe(
                select(selectors.getHistoryOrderByOrderId(orderId)),
                filter(order => order !== null && order !== undefined && order.isDownloading !== true),
                combineLatest(
                    this.store
                        .pipe(
                            select(selectors.getCurrentPickupTime),
                            filter(pickupTime => pickupTime !== null),
                            take(1),
                        )
                ),
                take(1)
            ).subscribe(([order, pickupTime]) => {
                if (!order.data) {
                    console.warn(`Invalid order data for order ${orderId}:`, order);

                    return;
                }

                this.store.dispatch(actions.ReorderCalculateRequest({
                    orderId,
                    locationNo: order.data.PickupLocation,
                    pickupTime
                }));
            });
    }

    public reorderToggleItemSelected(orderId: number, item: OLO.State.Cart.CartMenuFlowExtended | OLO.State.Cart.CartSimpleItemExtended): void {
        if (item._IsSelected) {
            return this.store.dispatch(actions.ReorderDeselectItem({ orderId, item }));
        }
        this.store.dispatch(actions.ReorderSelectItem({ orderId, item }));
    }

    public reorderAccept(orderId: number, locationNo: number, modalId: number = null): void {
        this.store
            .pipe(
                select(selectors.getCurrentPickupTime),
                switchMap(pickupTime => this.store
                    .pipe(
                        select(selectors.getReorder(orderId, locationNo, pickupTime))
                    )
                ),
                take(1)
            ).subscribe(reorder => {
                const menuFlows = reorder.data.cart.itemsMenuFlow.filter(menuFlow => menuFlow && menuFlow._IsSelected && !menuFlow._IsDisabled);

                const simpleItems = reorder.data.cart.itemsSimple.filter(simpleItem => simpleItem && simpleItem._IsSelected && !simpleItem._IsDisabled);

                this.store.dispatch(actions.CartSetupWithMultipleItems(
                    modalId,
                    locationNo,
                    menuFlows,
                    simpleItems
                ));
            });
    }

    public insertOnlineOrderUrl(model: OLO.DTO.OnlineOrderUrlBusinessModel): Observable<OLO.DTO.OnlineOrderUrlBusinessModel> {
        const mapedPostmodel: APIv3.OnlineOrderUrlBusinessModel = OnlineOrdersMapper.mapInsertOnlineOrderUrlPOSTRequest(model);

        return this.httpClient
            .post<APIv3.OnlineOrderUrlBusinessModel>(`${this.config.api.base}/OnlineOrders/${mapedPostmodel.OrderId}/InsertOnlineOrderUrl`, mapedPostmodel)
            .pipe(
                map((response: APIv3.OnlineOrderUrlBusinessModel) => OnlineOrdersMapper.mapInsertOnlineOrderUrlPOSTResponse(response))
            );
    }
}
